<template>
    <div
        v-if="hasStyleOrScriptTag"
        ref="customDescriptionWrapper"
        class="custom-description-wrapper"
    />
    <div
        v-else
        class="simple-description-wrapper"
        v-html="description"
    />
</template>

<script setup>
    import { computed, onMounted, ref } from 'vue';

    const props = defineProps({
        description: {
            type: String,
            required: false,
            default: ''
        }
    });

    const customDescriptionWrapper = ref(null);

    const hasStyleOrScriptTag = computed(() => {
        return props.description.includes('<script') || props.description.includes('<style');
    });

    onMounted(() => {
        if (hasStyleOrScriptTag.value) {
            const descriptionElement = document.createRange().createContextualFragment(props.description);
            customDescriptionWrapper.value.appendChild(descriptionElement);
        }
    });
</script>
